import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';

import englishMessages from 'ra-language-english';
import russianMessages from 'ra-language-russian';

const translations = { englishMessages, russianMessages };

 

const i18nProvider = polyglotI18nProvider(
    locale => translations[locale] ? translations[locale] : translations.russianMessages,
    resolveBrowserLocale('ru', { fullLocale: true }), // 'en' => Default locale when browser locale can't be resolved, { fullLocale: true } => Return full locale
    [
        { locale: 'en', name: 'English' },
        { locale: 'ru', name: 'Русский' }
    ],
);

export default i18nProvider;