import React, { useState, useCallback } from 'react'; 
import { ReferenceInput, AutocompleteInput } from 'react-admin';
import { makeStyles } from '@mui/styles';
import QuickCreateButton from './QuickCreateButton'; 
import QuickPreviewButton from './QuickPreviewButton';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});
 

const QuickCreateReferenceInput = props => {
    const classes = useStyles();
    const [version, setVersion] = useState(0);  
    const { getValues } = useFormContext();
    const handleChange = useCallback(() => setVersion(version + 1), [version]);
    const id = getValues()?.[props.source];      
    return (
        <div className={classes.root}>
            <ReferenceInput key={version} {...props}>
                <AutocompleteInput 
                    style={{minWidth: "300px"}}
                    validate={props.validate} 
                    label={props.label}  
                    optionText={props.optionText || "name"} />
            </ReferenceInput>
            <QuickCreateButton onChange={handleChange} 
                {...props}/> 
            {!!id && !!props.fields && 
                <QuickPreviewButton 
                        reference={props.reference} 
                        fields={props.fields} 
                        id={id} />}
        </div>
    );
};

export default QuickCreateReferenceInput;