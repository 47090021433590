import React from 'react'; 
import { 
    AutocompleteInput,  
    SimpleForm, 
    DateInput, 
    TextInput,  
    ReferenceInput,
    SelectInput, 
    NumberInput, 
    BooleanInput,
    NumberField,
    TextField, 
    required,  
    Toolbar,
    useStore, 
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';    
import InputMask from 'react-input-mask';
import { Box, Typography } from '@mui/material'; 
import { makeStyles } from '@mui/styles'; 
import NodeReferenceInput from '../nodes/NodeReferenceInput'; 
import DeviceModeInput from './DeviceModeInput';
import DeviceModeInputs from './DeviceModeInputs'; 
import NodeInputs from './NodeInputs';
import QuickCreateInput from '../components/QuickCreateInput';


const useStyles = makeStyles((theme) => {
  
    return (
        { 
        box: {
            border: '2px solid #DEDEDE',
            borderRadius: '5px',
            backgroundColor: 'white', 
            padding: "10px",
            margin: "5px",
        }
    })
});

const PhoneInput = (props) => (
    <InputMask mask="+9 (999) 999-99-99" {...props}>
        {(inputProps) => <TextInput {...props} {...inputProps} />}
    </InputMask>
 );


const MeterForm = props => {
    const classes = useStyles();
    const [node, setNodeId] = useStore("node_id", undefined);  
    return (
            <SimpleForm  
                {...props} 
                sx={ {padding: "5px"}}
                toolbar={<Toolbar  alwaysEnableSaveButton />}
                defaultValues={{ node: !!node ? Number(node): undefined, is_active: true }}
            >
                <Box display={{ xs: 'block', sm: 'flex' }} >
                    <Box flex={2}>
                        <Box className={classes.box}>
                            <Typography variant="h6" gutterBottom>
                                Измеритель
                            </Typography>
                            <NodeReferenceInput 
                                label="Узел"
                                source="node"
                                reference="node" 
                                perPage={10}
                                inputs={
                                    <>
                                        <TextInput 
                                            fullWidth
                                            source="name" 
                                            label='Имя'
                                            validate={[required()]} 
                                            />  
                                        <QuickCreateInput  
                                            label="Тип узла" 
                                            source="type" 
                                            reference="node/type" 
                                            suggestionLimit={30}
                                            validate={[required()]} 
                                            inputs={[<TextInput source='name' label="Название"/>]}
                                            /> 
                                        <ReferenceInput 
                                            label="Родитель" 
                                            source="parent" 
                                            reference="node"  
                                            validate={[required()]} 
                                            >
                                            <AutocompleteInput fullWidth optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`}/>     
                                        </ReferenceInput> 
                                        <TextInput 
                                            fullWidth
                                            label="Номер телефона" 
                                            source="phone"  />
                                        <TextInput 
                                            fullWidth
                                            source="account_id" 
                                            label='Лицевой счет/№ договора' 
                                            /> 
                                        <ReferenceArrayInput 
                                            label="Пользователи" 
                                            source="users" 
                                            reference="user" 
                                            >
                                            <AutocompleteArrayInput fullWidth optionText="username" />
                                        </ReferenceArrayInput>
                                        <TextInput 
                                            fullWidth
                                            source="description" 
                                            label="Описание" 
                                            /> 
                                    </>
                                }
                                validate={[required()]} 
                            /> 
                            <Box display={{ xs: 'block', sm: 'flex' }} >
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <QuickCreateInput 
                                        fullWidth
                                        label="Модель измерителя"
                                        source="type"
                                        reference="meter%2fmodel" 
                                        perPage={30}
                                        validate={[required()]}
                                        optionText={({name, proportionality_factor}) => (`${name}, k: ${proportionality_factor}`)}
                                        inputs={
                                            <>
                                                <TextInput 
                                                    fullWidth
                                                    source="name" 
                                                    label='Название' 
                                                    validate={[required()]}/> 
                                                <NumberInput 
                                                    fullWidth
                                                    source="proportionality_factor" 
                                                    label="Коэффициент пропорциональности" 
                                                    validate={[required()]}/> 
                                                <TextInput 
                                                    fullWidth
                                                    source="unit_of_measurement" 
                                                    label="Единица измерения" 
                                                    validate={[required()]}/>
                                                <TextInput 
                                                    fullWidth
                                                    source="description" 
                                                    label="Описание"/>
                                            </>
                                        }
                                        fields={ 
                                            [
                                                <TextField fullWidth source="name" label='Название'/>,
                                                <NumberField fullWidth source="proportionality_factor" label="Коэффициент пропорциональности"/>,
                                                <TextField fullWidth source="unit_of_measurement" label="Единица измерения" />,
                                                <TextField fullWidth source="description" label="Описание"/>
                                            ]
                                        }/>
                                    <ReferenceInput   
                                        source="resource_type" 
                                        reference="resource_type">
                                        <SelectInput 
                                            fullWidth 
                                            optionText="name" 
                                            label="Тип ресурса" 
                                            validate={[required()]}/>
                                    </ReferenceInput>
                                    <DateInput 
                                        fullWidth source="join_date" 
                                        label="Дата подключения"
                                        validate={[required()]} /> 
                                     <NodeInputs /> 
                                </Box>

                                <Box
                                    flex={1}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                >   
                                    <TextInput 
                                        source="serial_number" 
                                        label='Серийный номер'
                                        validate={[required()]} 
                                        fullWidth/>    
                                    <NumberInput 
                                        fullWidth 
                                        source="join_reading" 
                                        label="Показание при подключении" 
                                        validate={[required()]} />    
                                    <QuickCreateInput 
                                        fullWidth 
                                        label="Место установки" 
                                        source="installation_place"
                                        reference="installation_place"
                                        perPage={30}
                                        validate={[required()]} 
                                        inputs={
                                            <TextInput source="name" label="Название" />
                                        }/> 
                                </Box>
                            </Box> 
                        </Box> 
                        <Box className={classes.box}>
                            <Typography variant="h6" gutterBottom>
                                Потребитель
                            </Typography> 
                            <TextInput 
                                source="consumer" 
                                label='Название/ФИО' 
                                fullWidth/>  
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >                                      
                                    <SelectInput  
                                        source="client_sector" 
                                        label='Клиентский сектор'  
                                        choices={[
                                            {
                                                id: 'legal',
                                                name: 'Юридический сектор'
                                            },
                                            {
                                                id: 'private',
                                                name: 'Частный сектор'
                                            },
                                            {
                                                id: 'multi_apartment',
                                                name: 'Многоквартирный сектор'
                                            },
                                            {
                                                id: 'physical',
                                                name: 'Физический сектор'
                                            }
                                        ]}
                                        validate={[required()]} 
                                        fullWidth/>    
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                >  
                                    <QuickCreateInput 
                                        fullWidth
                                        label="Тип объекта" 
                                        source="object_type" 
                                        reference="object_type" 
                                        validate={[required()]}
                                        inputs={
                                            <TextInput source="name" label="Название" />
                                        }/>  
                                </Box> 
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <TextInput 
                                        source="apartment" 
                                        label='Квартира'
                                        fullWidth/> 
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <TextInput 
                                        source="account_id" 
                                        label='Лицевой счет/№ договора' 
                                        fullWidth/> 
                                </Box>
                                <Box
                                    flex={1}
                                    mr={{ xs: 0, sm: '0.5em' }}
                                > 
                                    <PhoneInput 
                                        source="phone" 
                                        label='Номер телефона' 
                                        fullWidth/> 
                                </Box> 
                            </Box>
                        </Box>
                    </Box> 
                    <Box flex={1}>
                        <Box className={classes.box} sx={{marginLeft: "10px"}}>
                            <Typography variant="h6" gutterBottom>
                                Устройство СПД
                            </Typography>
                            <ReferenceInput 
                                source="device" 
                                reference="device">
                                <AutocompleteInput 
                                    fullWidth  
                                    optionText="eui" 
                                    validate={[required()]}
                                    matchSuggestion={() => true} 
                                    label="Устройство СПД"/>    
                            </ReferenceInput>  
                            <DeviceModeInput />   
                            <DeviceModeInputs />
                        </Box> 
                        
                    </Box>
                </Box>
                <TextInput fullWidth multiline source="description" label="Описание"/>
                <BooleanInput source='is_active' label="Вкл/Выкл" />
            </SimpleForm>
    );
};

export default MeterForm;