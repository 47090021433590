import * as React from 'react';
 
import { 
    FilterList, 
    FilterListItem, 
    SearchInput, 
    AutocompleteInput,
    ReferenceInput
} from 'react-admin';  

import AddIcon from '@mui/icons-material/Add';
 
 

export const RelevantFilter = () => (
    <FilterList label="Измеритель добавлен" icon={<AddIcon />}>
        <FilterListItem
            label="Да"
            value={{ 
                meters__isnull: false 
            }}
        />
        <FilterListItem
            label="Нет"
            value={{ 
                meters__isnull: true
            }}
        /> 
    </FilterList>
);

const filters = [
    <SearchInput source="q" alwaysOn />,   
    <ReferenceInput 
        source="device__node_service_company" 
        label="Сервисная компания" 
        reference="service_company">
            <AutocompleteInput 
                style={{minWidth: "350px"}}
                optionText={choice => choice && `${choice.parent_name} -> ${choice.name}`} 
                fullWidth
                label="Сервисная компания" />
    </ReferenceInput>,   
    <ReferenceInput 
        source="resource_type" 
        label="Тип ресурса" 
        reference="resource_type">
            <AutocompleteInput 
                style={{minWidth: "250px"}}
                optionText="name" 
                fullWidth
                label="Тип ресурса" />
    </ReferenceInput>,   
    <ReferenceInput 
        source="type" 
        label="Модель измерителя" 
        reference="meter%2fmodel">
        <AutocompleteInput 
            style={{minWidth: "250px"}}
            optionText="name" 
            label="Модель измерителя" />
    </ReferenceInput>
    ]

export default filters;